.overview-main {
  .overall {
    .heading {
      display        : flex;
      justify-content: space-between;

      .brandSelectBox {
        margin-right: 20px;

        .dropdown {
          outline: none;

          .dropdown-menu {
            width    : 50px;
            transform: translate(-50px, 38px) !important;
            min-width: 155px !important;
          }

          button {
            outline: none;
          }

          .icon-down-arrow {
            margin-left: 5px;
          }

          .dropdown-toggle {

            &::after {
              display: none;
            }
          }
        }
      }

      h6 {
        display    : block;
        font-size  : 20px;
        color      : #000000;
        font-weight: 700;
        margin     : 0 0 20px;

        span.active {
          font-weight    : 700;
          text-decoration: none;
          color          : #000000;
        }

        .firstSpan {
          margin-left: 30px;
        }

        .secondSpan {
          margin         : 10px;
          text-decoration: none !important;
        }

        .thirdSpan {
          margin-left: 5px;
        }

        span {
          cursor         : pointer;
          font-weight    : normal;
          text-decoration: underline;
          font-size      : 14px;
          color          : #000;
        }

      }

    }

    .card-container {
      display  : flex;
      flex-wrap: wrap;

      .card {

        cursor            : pointer;
        display           : flex;
        flex-wrap         : wrap;
        flex-direction    : row;
        width             : calc(33.33% - 20px);
        margin            : 0 20px 20px 0;
        -webkit-box-shadow: 10px 7px 13px 0px rgba(140, 140, 140, 1);
        -moz-box-shadow   : 10px 7px 13px 0px rgba(140, 140, 140, 1);
        box-shadow        : 10px 7px 13px 0px rgba(140, 140, 140, 1);
        min-height        : 138px;
        padding           : 5px;

        padding-bottom: 0;

        &:nth-child(3n+3) {
          margin-right: 0;
        }

        .heading {
          font-size  : 18px;
          font-weight: 600;
        }

        .c-gray {
          color    : #7f7f7f;
          font-size: 15px;
        }

        .chip {
          font-weight: 600;
          margin     : 2px 0;
          width      : 58px;
          text-align : center;
          width      : auto;
        }

        .chip-ver {
          font-size: 14px;
        }

        .chip-yellow {
          background-color: #fdf3c8 !important;
        }

        &.active {
          background: #e2e0ff;
          transition: 0.5s;
        }

        .font15 {
          font-size: 15px;
        }

        .graphWrapper {
          width : 100%;
          height: 50px;

          .custom-tooltip {
            background: #846aff;
            color     : white;
            padding   : 5px;
          }
        }
      }
    }
  }

  .coverage-box {
    display     : block;
    margin-top  : 20px;
    box-shadow  : 10px 7px 13px 0px #8c8c8c;
    margin-right: 21px;

    .heading {
      display        : flex;
      justify-content: space-between;

      .left {
        h3 {
          span {
            font-weight: 400;
          }
        }
      }

      .right {
        font-weight: 500;
      }
    }

    .search-cond {
      .left {
        button {
          border      : none;
          background  : #f2f2f2;
          outline     : none;
          margin-right: 5px;

          &:hover,
          &.active {
            background: #846aff;
            color     : #fff;
            transition: .5s;
          }
        }
      }

      .right {
        width: 28% !important;

        .label {
          margin: 0 5px;
        }
      }

    }

    h3 {
      display    : block;
      font-size  : 20px;
      font-weight: 700;
    }

  }

  .replenishment-box {
    display   : block;
    margin-top: 20px;

    .c-gray {
      color      : #7f7f7f;
      font-size  : 15px;
      font-weight: 400;
    }

    h3 {
      display    : block;
      font-size  : 20px;
      font-weight: 700;
      margin     : 0 0 20px;
    }

    .card-container {
      display  : flex;
      flex-wrap: wrap;

      .card {
        background        : #846aff00;
        display           : flex;
        flex-wrap         : wrap;
        flex-direction    : row;
        width             : calc(33.33% - 20px);
        margin            : 0 20px 20px 0;
        -webkit-box-shadow: 10px 7px 13px 0px rgba(140, 140, 140, 1);
        -moz-box-shadow   : 10px 7px 13px 0px rgba(140, 140, 140, 1);
        box-shadow        : 10px 7px 13px 0px rgba(140, 140, 140, 1);

        .heading {
          font-size  : 18px;
          font-weight: 600;

          &.c-gray {
            color: #7f7f7f;
          }

        }

        .c-gray {
          color    : #7f7f7f;
          font-size: 15px;
        }

        .chip-ver {
          font-size: 14px;

          &.font-weight {
            font-weight: 700;
          }

        }

      }

      .minHeight {
        min-height: 22px;
      }


    }

  }

  .real-box {
    display     : block;
    margin-top  : 20px;
    margin-right: 21px;
    box-shadow  : 10px 7px 13px 0px #8c8c8c;

    h3 {
      display    : block;
      font-size  : 20px;
      font-weight: 700;
    }

    .heading {
      display        : flex;
      justify-content: space-between;

      .left {
        h3 {
          span {
            font-weight: 400;
          }
        }
      }

      .right {
        font-weight: 500;
      }
    }

    .search-cond {
      .left {
        button {
          border      : none;
          background  : #f2f2f2;
          outline     : none;
          margin-right: 5px;

          &:hover,
          &.active {
            background: #846aff;
            color     : #fff;
            transition: .5s;
          }
        }
      }

      .right {
        width: 35% !important;

        .label {
          margin: 0 5px;
        }
      }

    }

    .maingraph {
      width : 100%;
      height: 350px
    }
  }

  .stores-box {
    display   : block;
    margin-top: 20px;

    h3 {
      display    : block;
      font-size  : 20px;
      font-weight: 700;
      margin     : 0 0 20px;

      a {
        font-size      : 16px;
        color          : #7f7f7f;
        font-weight    : 400;
        text-decoration: underline;
      }

    }

    .store-main {
      display        : flex;
      flex-wrap      : wrap;
      width          : 100%;
      justify-content: space-between;

      .store-boxs {
        display     : block;
        width       : 31.60%;
        padding     : 20px;
        margin-right: 15px;

        h4 {
          display    : flex;
          flex-wrap  : wrap;
          width      : 100%;
          margin     : 0 0 20px 0;
          font-size  : 16px;
          color      : #7f7f7f;
          font-weight: normal;

          span {
            margin-left: auto;
          }

        }

        .bg-gray {
          background   : #f8f7fc;
          // padding   : 10px;
          padding      : 5px 10px;

        }

        h2 {
          display    : block;
          margin     : 0 0 20px;
          font-size  : 22px;
          font-weight: bold;
          text-align : center;
        }

        .store-list {
          display      : flex;
          flex-wrap    : wrap;
          width        : 100%;
          align-items  : center;
          margin-bottom: 10px;



          .title {
            font-size: 16px;
            color    : #000000;

            .back-btn {
              margin-right: 10px;
            }
          }

          .price {
            font-size  : 16px;
            color      : #000;
            font-weight: bold;
            padding    : 7px 12px;
            margin-left: auto;
            min-width  : 70px;
            text-align : center;

            &.yellow {
              background-color: #fff2cc;
            }

            &.red {
              background-color: #f8cbad;
            }

            &.green {
              background-color: #e4f0d7;
            }

            &.red-light {
              background-color: #fbe5d6;
            }

          }

          .chip {
            text-align : center;
            display    : block;
            margin-left: 10px;
            width      : auto;

            h6 {
              display    : block;
              margin     : 0;
              font-size  : 16px;
              color      : #7f7f7f;
              line-height: 1;
              font-weight: normal;
            }

            span {
              display    : block;
              line-height: 1;
              font-size  : 14px;
              color      : #7f7f7f;
            }

          }


        }

      }

    }

  }

  .product {
    h2 {
      font-size  : 20px !important;
      font-weight: normal !important;
      text-align : inherit !important;
    }

    .store-list {
      .product-img {
        height             : 130px;
        width              : 200px;
        background-position: center;
        background-repeat  : no-repeat;
        background-size    : contain;
        /* text-align      : center; */
        margin             : 0 auto;
      }
    }

  }

  .other-general {
    .chip {
      font-weight     : 600;
      background-color: #e4f0d7;
      margin          : 2px 0;
      width           : 58px;
      text-align      : center;

      &.green {
        background-color: #e4f0d7;
      }

      &.gray {
        background-color: #f2f2f2;
      }

      &.red-light {
        background-color: #fbe5d6;
      }
    }


  }


}