.main-content {
  display: block;

  .form {
    width: 100%;

    @media only screen and (min-width: 1600px) {
      width: 85%;
    }

    @media only screen and (min-width: 2000px) {
      width: 65%;
    }

    @media only screen and (max-width: 1600px) {
      width: 100%;
    }
  }

  .title {
    display: block;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    line-height: 1;
    padding: 22px 30px;
    font-size: 24px;
    font-weight: bold;
    color: var(--color-primary);
    margin: 0 0 20px;
    display: flex;
    flex-wrap: wrap;

    .heading {
      .back-btn {
        margin-right: 10px;
      }
    }

    @media only screen and (max-width: 1600px) {
      margin: 0 0 30px;
    }

    @media only screen and (max-width: 1440px) {
      font-size: 20px;
      padding: 20px;
    }

    .right-content {
      display: flex;
      flex-wrap: wrap;
      width: auto;

      &.select-box {
        font-size: 15px;
        color: #666;
        font-weight: 400;

        p {
          margin: 0;
          padding: 0 0px 0 30px;
          line-height: 1;
          font-size: 15px;
          font-weight: 500;

          i {
            margin-left: 30px;
          }
        }

        .dropdown {
          margin-left: 10px;

          .dropdown-toggle {
            width: auto;
            display: flex;
            flex-wrap: wrap;
            line-height: 1;
            padding: 0;

            &::after {
              display: none;
            }

            i {
              margin-left: 10px;
            }
          }

          .dropdown-menu {
            a {
              margin: 0;
            }
          }
        }
      }

      a {
        font-size: 15px;
        color: #666;
        font-weight: 400;
        margin-left: 30px;

        img {
          margin: 0 10px;
        }
      }
    }
  }

  .title.severityChangedColor {
    box-shadow: 0 2px 2px 0 rgba(255, 0, 0, 0.16) !important;
  }

  .card {
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    display: block;
    padding: 0;
    margin: 0 0 30px;

    .card-header {
      background-color: #ffffff;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 30px;
      border: 0;
      border-radius: 0;

      @media only screen and (max-width: 1600px) {
        padding: 15px;
      }

      .back-button {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-right: 10px;
      }

      .search-boxs {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        width: 380px;
        margin-right: auto;

        @media only screen and (max-width: 1600px) {
          width: 280px;
        }

        .form-control {
          border: 0;
          border-radius: 50px;
          background: #ffffff;
          padding: 15px 52px 15px 23px;
          height: auto;
          line-height: 1;
          font-size: 14px;
          color: #757280;
          border: solid 1px #efeaea;

          &:focus {
            box-shadow: none;
          }
        }

        .btn.btn-light {
          position: absolute;
          right: 0;
          top: 0;
          border: 0;
          margin: 15px 0;
          padding: 0 24px 0 13px;
          background: transparent;
          opacity: 0.3;
          border-left: solid 1px rgba(112, 112, 112, 0.5);

          img {
            display: block;
          }
        }
      }

      .btn.btn-link {
        margin-left: auto;
        padding: 12px 20px 12px 32px;
        line-height: 1;
        font-size: 15px;
        color: #666;
        text-decoration: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        img {
          margin-left: 10px;
        }

        &:hover {
          background-color: #f9f9f9;
        }
      }
    }

    .card-body {
      padding: 0;
      margin: 0;

      .ReactTable {
        border: 0;

        .rt-noData {
          z-index: 1000;
        }
      }

      .distribution-bg {
        .category-title {
          font-size: 16px;
          padding: 10px 10px 10px 50px;
        }

        .card {
          border-radius: 0;
          box-shadow: none;
          border: 0;
          margin: 0;
          overflow: initial;

          table {
            tr {
              &:nth-child(even) {
                background-color: #e7e7e7;
              }

              td {
                padding: 10px;
                font-size: 14px;
                // width    : 57px;

                &:first-child {
                  padding-left: 50px;
                }
              }
            }
          }
        }
      }

      .rt-table {
        margin: 0;
        position: relative;
        // overflow: initial; // for cal isuue

        .rt-tr {
          position: relative;

          &.-odd {
            background-color: #e7e7e7;
          }
        }

        .rt-thead {
          box-shadow: none;
        }

        .rt-tbody {
          overflow: initial;
          position: relative;
          z-index: 999;
        }

        .rt-th {
          font-size: 16px;
          font-weight: 500;
          line-height: 1;
          // padding               : 30px;
          padding: 15px 20px;
          border-top: 0;
          text-align: center;
          border-right: 0;
          box-shadow: none;
          border-bottom: solid 1px rgba(112, 112, 112, 0.4);

          // @media only screen and (max-width: 1500px) and (min-width:900px) {
          //   padding: 10px 10px 10px 22px;
          // }

          @media only screen and (max-width: 1600px) {
            padding: 15px;
          }
        }

        .rt-tr {
          &:hover {
            .rt-td {
              background-color: #5b5b5a;
              color: #fff;
              cursor: pointer;

              a {
                color: #fff;

                &.edit-icon {
                  background: url(../../images/pencil-hover.png) left center no-repeat;
                }
              }
            }
          }
        }

        .rt-td {
          border: 0;
          font-size: 16px;
          font-weight: 400;
          line-height: 1;
          padding: 12px 20px;
          color: #666666;
          border-right: 0;
          box-shadow: none;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;

          @media only screen and (max-width: 1600px) {
            padding: 12px 15px;
            font-size: 14px;
          }

          &.rt-expandable {
            padding: 2px;
          }

          .rt-expander {
            background: url(../../images/plus.svg);
            height: 15px;
            width: 15px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            &.-open {
              background: url(../../images/minus.svg);
              height: 15px;
              width: 15px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }

            &:after {
              display: none;
            }
          }

          .checkbox {
            position: relative;
            display: inline-block;
            cursor: pointer;

            input {
              position: absolute;
              left: 0;
              top: 0;
              margin: 0;
              opacity: 0;
              padding: 0;
              cursor: pointer;
              height: 100%;
              width: 100%;

              + label {
                width: 20px;
                height: 20px;
                border: solid 1px #d8d8d8;
                background-color: #ffffff;
                margin: 0;
                padding: 0;
                border-radius: 2px;
                box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
              }

              &:checked {
                + label {
                  background: #fff url(../../images/checked.png) center center no-repeat;
                }
              }
            }
          }

          .form-control {
            border-radius: 6px;
            border: solid 1px #d8d8d8;
            background-color: #ffffff;
            padding: 12px 20px;
            height: auto;
            width: 100%;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            &.select-arrow {
              background: #fff url(../../images/select-arrow.png) right 10px center no-repeat;
            }

            @media only screen and (max-width: 1600px) {
              padding: 10px 15px;
              font-size: 14px;
            }
          }

          a {
            display: inline-flex;
            font-size: 16px;
            color: #666;
            line-height: 16px;
            text-decoration: none;

            @media only screen and (max-width: 1600px) {
              font-size: 14px;
            }

            &.edit-icon {
              background: url(../../images/pencil.png) left center no-repeat;
              padding-left: 20px;
              height: 16px;
            }

            &.delete-icon {
              background: url(../../images/trash.svg) left center no-repeat;
              background-size: auto 100%;
              padding-left: 20px;
              height: 16px;
            }

            img {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .card.promotion {
    .read-only-push {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.radio-group {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;

  .radio-box {
    position: relative;
    width: auto;

    label {
      display: block;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.5);
      text-align: center;
      width: 100%;
      margin: 0;

      span {
        display: block;
        width: 100%;
        font-weight: bold;
        color: #000;
      }

      input {
        border: solid 1px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        padding: 0;
        text-align: center;
        width: 40px;
        height: 40px;
        margin: 0;
        line-height: 1;
        font-size: 13px;
      }
    }
  }
}

.submit_btn {
  .btn.btn-primary {
    display: inline-block;
    line-height: 1;
    background: #666;
    border-radius: 6px;
    border: 0;
    padding: 14px 47px;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
  }
}

.modal_new_seo .modal-header button.close {
  color: #adadad;
  font-size: 34px;
  padding: 12px 12px;
}

.modal_new_seo .modal-header {
  border-bottom: 1px solid #ececec;
  font-size: 20px;
  font-weight: bold;
  color: var(--color-primary);
  text-transform: capitalize;
}

.modal_new_seo .modal-dialog .modal-content {
  max-width: 700px;
  border: 0;
}

.modal_new_seo .modal-dialog {
  margin: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frm_grp .react-datepicker-wrapper {
  width: 100%;
}

.frm_grp lable {
  font-size: 16px;
  display: block;
  color: #212529;
  margin-bottom: 2px;
}

.frm_grp input {
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  height: 36px;
  color: #666;
  padding: 4px 8px;
}

.frm_grp select {
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  height: 36px;
  color: #666;
  padding: 4px 8px;
}

.react-datepicker-wrapper {
  display: block;
}

.frm_grp {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
  margin-bottom: 10px;
}

.frm_full {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  span {
    font-size: 11px;
    display: block;
    width: 100%;
    color: #f00;
    margin: 5px 0 0 0;
  }
}

.btn_pop {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 15px;
  margin-top: 15px;
}

button.btn_grey:hover {
  background: transparent;
  color: #666;
}

button.btn_grey {
  background: #666666;
  color: #fff;
  font-size: 14px;
  padding: 8px 32px;
  border-radius: 4px;
  border: 1px solid #666666;
  transition: ease-in-out 0.5s;
  line-height: 20px;
}

.btn_white:hover {
  background: #666;
  color: #fff;
}

.btn_white {
  background: transparent;
  color: #666;
  font-size: 14px;
  padding: 8px 32px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  transition: ease-in-out 0.5s;
  line-height: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}

.btn_pop button {
  margin-right: 20px;
}

.frm_space {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
  margin-bottom: 10px;
}

.test {
  animation: fadein 1s;
  -moz-animation: fadein 1s;
  /* Firefox */
  -webkit-animation: fadein 1s;
  /* Safari and Chrome */
  -o-animation: fadein 1s;
  /* Opera */
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
