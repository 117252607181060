@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900&display=swap");
@import url("./assets/variables.scss");

html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

body.body-main {
  background: #f8f7fc url(./images/body-dark-background.png) top right no-repeat;
  background-size: 100% auto;
}

#root {
  min-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

p {
  color: #212529;
}

@font-face {
  font-family: "icomoon";
  src: url("./assets/fonts/icomoon.eot?2pvn9h");
  src: url("./assets/fonts/icomoon.eot?2pvn9h#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/icomoon.ttf?2pvn9h") format("truetype"),
    url("./assets/fonts/icomoon.woff?2pvn9h") format("woff"),
    url("./assets/fonts/icomoon.svg?2pvn9h#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  /* speak: none; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add-extensions:before {
  content: "\e900";
}

.icon-ad-groups:before {
  content: "\e901";
}

.icon-audiences:before {
  content: "\e902";
}

.icon-billing:before {
  content: "\e903";
}

.icon-bulk-action:before {
  content: "\e904";
}

.icon-calendar:before {
  content: "\e905";
}

.icon-clock:before {
  content: "\e906";
}

.icon-close:before {
  content: "\e907";
}

.icon-compaigns:before {
  content: "\e908";
}

.icon-demographics:before {
  content: "\e909";
}

.icon-dislike:before {
  content: "\e90a";
}

.icon-down-arrow:before {
  content: "\e90b";
}

.icon-expand:before {
  content: "\e90c";
}

.icon-humber-arrow:before {
  content: "\e90d";
}

.icon-humber-menu:before {
  content: "\e90e";
}

.icon-keyword:before {
  content: "\e90f";
}

.icon-lamp .path1:before {
  content: "\e910";
  color: rgb(255, 209, 92);
}

.icon-lamp .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-lamp .path3:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(52, 74, 94);
}

.icon-lamp .path4:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(52, 74, 94);
}

.icon-lamp .path5:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(65, 90, 107);
}

.icon-lamp .path6:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(65, 90, 107);
}

.icon-lamp .path7:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-lamp .path8:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-lamp .path9:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-lamp .path10:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-lamp .path11:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-lamp .path12:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-lamp .path13:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(255, 209, 92);
}

.icon-landing-pages:before {
  content: "\e91d";
}

.icon-left-nevigation:before {
  content: "\e91e";
}

.icon-measurment:before {
  content: "\e91f";
}

.icon-notification:before {
  content: "\e920";
}

.icon-overview:before {
  content: "\e921";
}

.icon-placements:before {
  content: "\e922";
}

.icon-planning:before {
  content: "\e923";
}

.icon-polygon-down:before {
  content: "\e924";
}

.icon-polygon-left:before {
  content: "\e925";
}

.icon-product-groups:before {
  content: "\e926";
}

.icon-question:before {
  content: "\e927";
}

.icon-recommendations:before {
  content: "\e928";
}

.icon-report:before {
  content: "\e929";
}

.icon-right-arrow:before {
  content: "\e92a";
}

.icon-left-arrow:before {
  content: "\e9c0";
}

.icon-right-nevigation:before {
  content: "\e92b";
}

.icon-select-down-arrow:before {
  content: "\e92c";
}

.icon-settings:before {
  content: "\e92d";
}

.icon-shared-liberary:before {
  content: "\e92e";
}

.icon-tool-setting:before {
  content: "\e92f";
}

.icon-view:before {
  content: "\e930";
}

.Toastify__toast-container .Toastify__toast {
  min-height: 30px !important;
}

.Toastify__toast-container .Toastify__toast--success {
  background-color: var(--color-primary);
  font-size: 15px;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.invalid {
  border: 1px solid red;
}

.gray {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
}

.white {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0);
}

.placeholder {
  position: relative;
}

.placeholder::after {
  position: absolute;
  left: 13px;
  top: 8px;
  content: attr(data-placeholder);
  pointer-events: none;
  opacity: 0.6;
}

#input-wrapper * {
  position: absolute;
}

#input-wrapper label {
  z-index: 99;
  line-height: 25px;
  padding: 2px;
  margin-left: 5px;
}

#input-wrapper input {
  height: 25px;
  text-indent: 35px;
}

.cardHeading {
  position: absolute;
  top: -17px;
  left: 18px;
  background-color: #fff;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}

.row lable {
  font-weight: 500;
}

.altrow:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
}

.altrow:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0);
  padding: 10px;
}

.ck-editor__editable_inline {
  min-height: 140px;
}

/* .main-content .card .card-body .rt-table .rt-th {
  width: auto !important;
}

.main-content .card .card-body .rt-table .rt-td {
  width: auto !important;
} */

.react-table-column-flex-grow-1 {
  flex-grow: 1 !important;
  width: unset !important;
  flex-basis: 5px !important;
  max-width: none !important;
}

#myLabel input[type="file"] {
  position: absolute;
  display: none;
  top: -1000px;
}

/***** Example custom styling *****/
#myLabel {
  /* border: 2px solid #aaa; */
  /* border-radius: 4px; */
  padding: 2px 5px;
  margin: 2px;
  /* background: #ddd; */
  /* display      : inline-block; */
  width: 100%;
}

#myLabel:hover {
  cursor: pointer;
}

/* #myLabel:hover {
  background: #ccc;
} */

/* #myLabel:active {
  background: #ccf;
} */

#myLabel :invalid + span {
  color: #a44;
}

/* #myLabel :valid + span {
  color: #4A4;
} */

.rt-th > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-confirm-alert-overlay {
  z-index: 1444 !important;
}

.shine {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

.overview-main .overall .card-container .loadingCard {
  display: block !important;
  width: 325px;
  height: 100px;
  margin-top: 15px;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-read-only {
  background: #eeeeee;
  cursor: not-allowed;
}

.rt-td .dropdown .dropdown-toggle::after {
  z-index: 9999 !important;
  display: none;
}

.rt-td .dropdown .dropdown-toggle {
  color: #6c757d;
  background-color: transparent !important;
  border-color: transparent !important;
}

.rt-td.actionColumn {
  overflow: inherit !important;
}

/*
.rt-td.actionColumn .dropdown .dropdown-menu a {
  color: #666 !important;
}

.main-content .card .card-body .rt-table .rt-tr:hover .rt-td a {
  color: #666 !important;
} */

.main-content .card .card-body .rt-table .rt-tr:hover .rt-td.actionColumn a {
  color: #666 !important;
}

.main-content
  .card
  .card-body
  .rt-table
  .rt-tr:hover
  .rt-td.actionColumn
  button {
  color: #fff !important;
}

.actionColumn .dropdown button {
  color: #666 !important;
}

.actionColumn a.dropdown-item {
  display: block !important;
  color: #666;
}

.checkbox {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.checkbox input:checked + label {
  background: #fff
    url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAABHNCSVQICAgIfAhkiAAAAPZJREFUKFONkbFKA2EQhL+5604RUlr7Cj6ABsFKkIDJRW2sBW1yP5ZX6tlcYZXKQEgslVgkndj7DlamVCGn4CkrCUSiYJItd/l2Z2fEnBVVbEUeLRP7modxoa0a9IDM/6I4E6qVbcPz6Rg85j7FtKn+VMhVbM9EQ+Lh453N9FovQ2WKtyx4Wiav15VPSo2q5gRnQC8YUIo7ehvP5UK7MfBelyiNwahqqeDYoLnQ5yC+0+fkQtVCWxN0Je6DAdvZIpeCHYyL5IojkP01a/STK9s6Hl1EBhSGraSt8/+c/TFidFHcCg6TthrTovjl3smuFU5bep6V3TeJNlDu3/fNNgAAAABJRU5ErkJggg==")
    50% no-repeat;
}

.checkbox input + label {
  width: 20px;
  height: 20px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  margin: 0;
  padding: 0;
  border-radius: 2px;
  box-shadow: 0 2px 2px rgb(0 0 0 / 16%);
}

a.dropdown-item.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.main-content .card .card-body .rt-table .rt-td a.dropdown-item.disabled {
  cursor: not-allowed;
}

.rt-th div {
  padding-bottom: 5px !important;
}

.react-confirm-alert-body h1 {
  display: none;
}

.react-confirm-alert-button-group {
  justify-content: space-between !important;
}

.react-confirm-alert-button-group > button {
  background: #666 !important;
  color: #fff !important;
}

.react-confirm-alert-button-group > button:hover {
  opacity: 0.7;
  transition: 0.5s;
}

.react-confirm-alert-button-group button:nth-child(1) {
  margin-left: 20px !important;
}

.react-confirm-alert-button-group button:nth-child(2) {
  margin-right: 40px !important;
}

button.icon-down-arrow.rotate {
  transform: rotate(180deg) !important;
}

button.icon-down-arrow:hover {
  /* background-color: #484847 !important;
  color           : white; */
  font-weight: 700;
}

.addbtn-style,
.up-downbtn {
  padding: 5px;
  border: none;
  font-size: 15px;
}

button.already-added {
  background: #484847;
  color: white;
}

.main .main_child {
  display: inline-block;
  position: relative;
}

.child_wrapper {
  display: inline-block;
  position: relative;
}

.someone-else {
  color: #459fed;
  display: inline-block;
  overflow-y: hidden;
  vertical-align: bottom;
  max-height: 1.59em;
  border-bottom: 5px solid #e2e1e5;
  margin: 0 13px;
  width: 150px;
  text-align: center;
}

._23wfu {
  display: inline-block;
  transform: translateY(0);
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.child_wrapper_2 ._23wfu {
  transform: translateY(100px);
}

.child_wrapper ._3RLzW {
  background: #fff;
  font-weight: 400;
  height: 0;
  left: 0;
  min-width: 100%;
  overflow: hidden;
  position: absolute;
  top: 100%;
  width: auto;
  z-index: 100;

  transition: height 0.4s cubic-bezier(0.23, 1, 0.32, 1),
    box-shadow 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.child_wrapper ._3RLzW {
  font-weight: 400;
  box-shadow: 15px 15px 20px 0 rgb(194 199 203 / 16%),
    -15px 0 20px 0 rgb(194 199 203 / 14%);
}

.main._2Vrov {
  display: inline-block;
}

/* .drop-down-site-owner:hover ._3RLzW {
  display: block !important;
  height : auto;
} */

.drop-down-site-owner .placeholder {
  color: rgb(0, 0, 0);
  opacity: 0.5;
  cursor: pointer;
}

._2nGmU {
  list-style: none;
  padding: 10px 10px 0px 10px;
  /* background: #6c6c6b2b; */
}

._2nGmU li {
  cursor: pointer;
}

._2nGmU li:last-child {
  margin-bottom: 8px;
}

._2nGmU li:nth-child(2) {
  margin-top: 8px;
}

._2nGmU li:hover {
  color: #116dff;
  transition: 0.5s;
}

.child_wrapper._12Ay3 ._3RLzW {
  box-shadow: 15px 15px 20px 0 rgb(194 199 203 / 16%),
    -15px 0 20px 0 rgb(194 199 203 / 14%);
}

.q-row {
  padding: 0px 10px;
}

/* .q-row:hover {
  background: #e2e1e54d;
} */

.main-content .title {
  display: flex;
  justify-content: space-between;
}
