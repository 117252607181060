header {
  // padding      : 10px 44px 0 50px;
  padding: 15px 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-content: center;
  align-items: center;

  h1 {
    font-size: 19px;
    color: #333333;
    line-height: 1;
    margin: auto 60px auto 0;
    font-weight: 700;

    @media only screen and (max-width: 1600px) {
      margin-right: 20px;
    }

    @media only screen and (max-width: 1366px) {
      font-size: 16px;
    }
  }

  .search-boxs {
    display: block;
    position: relative;
    width: 300px;
    margin-right: 20px;

    @media only screen and (max-width: 1600px) {
      width: 260px;
    }

    @media only screen and (max-width: 1366px) {
      width: 200px;
      margin-right: 0;
    }

    .form-control {
      border: 0;
      border-radius: 50px;
      background: #ffffff;
      padding: 15px 52px 15px 23px;
      height: auto;
      line-height: 1;
      font-size: 14px;
      color: #757280;

      @media only screen and (max-width: 1366px) {
        padding: 15px 42px 15px 20px;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .btn.btn-light {
      position: absolute;
      right: 0;
      top: 0;
      border: 0;
      margin: 15px 0;
      padding: 0 24px 0 13px;
      background: transparent;
      opacity: 0.3;
      border-left: solid 1px rgba(112, 112, 112, 0.5);

      @media only screen and (max-width: 1366px) {
        padding: 0 13px;
      }

      img {
        display: block;
      }
    }
  }

  .language_select_box {
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    height: 36px;
    color: #666;
    padding: 4px 8px;
  }

  .reports-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: auto;
    margin-left: auto;

    .dropdown {
      display: block;
      padding: 0 10px;
      position: relative;

      @media only screen and (max-width: 1366px) {
        padding: 0 10px;
      }

      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 5px;
        bottom: 5px;
        border-left: solid 1px #fff;
      }

      &:last-child {
        &::before {
          display: none;
        }
      }

      .btn.btn-primary {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background: none;
        border: 0;
        font-size: 15px;
        color: #333333;
        line-height: 1;
        text-decoration: none;
        padding: 0;

        @media only screen and (max-width: 1366px) {
          font-size: 14px;
        }

        &::after {
          display: none;
        }

        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active:focus,
        &:focus {
          box-shadow: none;
          background: none;
          outline: 0;
        }

        i {
          height: 30px;
          font-size: 28px;
          margin-right: 10px;

          @media only screen and (max-width: 1366px) {
            font-size: 20px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          }
        }
      }

      ul {
        li {
          a.active {
            color: var(--color-primary);
            font-weight: 600;
          }
        }
      }
    }
  }

  .user-list {
    display: flex;
    flex-wrap: wrap;
    // margin-left: auto;
    align-items: center;

    .help {
      display: block;

      .dropdown-toggle {
        background: none;
        padding: 0;
        border: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 44px;
        border-radius: 0px;
        position: relative;
        color: rgba(51, 51, 51, 0.7);
        font-size: 30px;

        @media only screen and (max-width: 1366px) {
          font-size: 20px;
        }

        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active:focus,
        &:focus {
          box-shadow: none;
          background: none;
          outline: 0;
        }

        span {
          position: absolute;
          right: -2px;
          top: 8px;
          width: 15px;
          height: 15px;
          background: var(--color-primary-hover);
          border-radius: 50%;
          font-size: 7px;
          color: #fff;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
        }

        &::after {
          display: none;
        }
      }
    }

    .notification {
      display: block;
      margin-left: 30px;

      @media only screen and (max-width: 1366px) {
        margin-left: 20px;
      }

      .dropdown-toggle {
        background: none;
        padding: 0;
        border: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 44px;
        border-radius: 0px;
        position: relative;
        color: rgba(51, 51, 51, 0.7);
        font-size: 30px;

        @media only screen and (max-width: 1366px) {
          font-size: 20px;
        }

        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active:focus,
        &:focus {
          box-shadow: none;
          background: none;
          outline: 0;
        }

        span {
          position: absolute;
          right: -2px;
          top: 8px;
          width: 15px;
          height: 15px;
          background: var(--color-primary-hover);
          border-radius: 50%;
          font-size: 7px;
          color: #fff;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
        }

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        width: 425px;
        padding: 15px;
        position: relative;

        @media only screen and (max-width: 1366px) {
          padding: 20px;
        }

        .close-btn {
          position: absolute;
          right: 10px;
          top: 3px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.35);
          border: 0;
          padding: 0;
        }

        h2 {
          display: block;
          font-size: 18px;
          color: var(--color-primary-hover);
          line-height: 1;
          margin: 0 0 15px;

          @media only screen and (max-width: 1366px) {
            font-size: 16px;
          }
        }

        .horizontal {
          overflow-x: hidden;
          width: 100%;
          position: relative;
        }

        .configure-btn {
          display: block;
          margin: 15px 0;
          line-height: 1;
          font-size: 14px;
          color: var(--color-primary-hover);
          text-decoration: none;

          i {
            margin-right: 10px;
          }
        }
      }

      .notification-main {
        display: block;
        transition: all 0.5s;
        left: 0;
        position: relative;

        &.open {
          left: -100%;
          height: 400px;
        }

        .notification-boxs {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          width: 100%;
          margin: 0 0 15px;
          padding: 15px;
          background: rgba(245, 245, 245, 0.53);

          @media only screen and (max-width: 1366px) {
            padding: 10px;
          }

          .icon-question {
            width: 18px;
            color: var(--color-primary-hover);
            font-size: 18px;
          }

          img {
            width: 18px;
            font-size: 18px;
          }

          .content {
            width: calc(100% - 18px);
            padding: 0 0 0 10px;

            h3 {
              display: block;
              font-size: 19px;
              color: #666666;
              line-height: 1;

              @media only screen and (max-width: 1366px) {
                font-size: 16px;
              }
            }

            //added here
            p {
              display: block;
              font-size: 14px;
              color: #999999;
              margin: 0 0 15px;
              line-height: 18px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              height: 52px;

              @media only screen and (max-width: 1366px) {
                font-size: 13px;
              }
            }

            .detail-box {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              width: 100%;

              .view {
                cursor: pointer;
                display: flex;
                flex-wrap: wrap;
                font-size: 14px;
                color: #666666;
                line-height: 1;
                margin-right: 10px;
                padding-right: 10px;
                border-right: solid 1px rgba(0, 0, 0, 0.26);
                text-decoration: none;

                @media only screen and (max-width: 1366px) {
                  font-size: 13px;
                }

                &:hover {
                  color: var(--color-primary-hover);
                }

                i {
                  margin-right: 5px;
                }
              }

              .dismiss {
                cursor: pointer;
                display: flex;
                flex-wrap: wrap;
                font-size: 14px;
                color: #666666;
                line-height: 1;
                text-decoration: none;

                @media only screen and (max-width: 1366px) {
                  font-size: 13px;
                }

                &:hover {
                  color: var(--color-primary-hover);
                }

                i {
                  margin-right: 5px;
                }
              }

              .calendar {
                display: flex;
                flex-wrap: wrap;
                font-size: 14px;
                color: #666666;
                line-height: 1;
                text-decoration: none;

                @media only screen and (max-width: 1366px) {
                  font-size: 13px;
                }

                &:hover {
                  color: var(--color-primary-hover);
                }

                i {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }

      .view-code {
        display: block;
        position: absolute;
        left: 100%;
        top: 0;
        transition: all 0.5s;
        width: 100%;
        padding-left: 40px;

        &.open {
          left: 0;
        }

        .back-button {
          position: absolute;
          left: 10px;
          top: 4px;
        }

        .notification-boxs {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          width: 100%;
          margin: 0 0 15px;
          padding: 15px;
          background: rgba(245, 245, 245, 0.53);

          @media only screen and (max-width: 1366px) {
            padding: 10px;
          }

          .icon-question {
            width: 18px;
            color: var(--color-primary-hover);
            font-size: 18px;
          }

          img {
            width: 18px;
            font-size: 18px;
          }

          .content {
            width: calc(100% - 18px);
            padding: 0 0 0 10px;

            h3 {
              display: block;
              font-size: 19px;
              color: #666666;
              line-height: 1;

              @media only screen and (max-width: 1366px) {
                font-size: 16px;
              }
            }

            p {
              display: block;
              font-size: 14px;
              color: #999999;
              margin: 0 0 15px;
              line-height: 18px;

              span {
                margin-right: 5px;
              }

              @media only screen and (max-width: 1366px) {
                font-size: 13px;
              }
            }

            .detail-box {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              width: 100%;

              .view {
                cursor: pointer;
                display: flex;
                flex-wrap: wrap;
                font-size: 14px;
                color: #666666;
                line-height: 1;
                margin-right: 10px;
                padding-right: 10px;
                border-right: solid 1px rgba(0, 0, 0, 0.26);
                text-decoration: none;

                @media only screen and (max-width: 1366px) {
                  font-size: 13px;
                }

                &:hover {
                  color: var(--color-primary-hover);
                }

                i {
                  margin-right: 5px;
                }
              }

              .dismiss {
                cursor: pointer;
                display: flex;
                flex-wrap: wrap;
                font-size: 14px;
                color: #666666;
                line-height: 1;
                text-decoration: none;

                @media only screen and (max-width: 1366px) {
                  font-size: 13px;
                }

                &:hover {
                  color: var(--color-primary-hover);
                }

                i {
                  margin-right: 5px;
                }
              }

              .calendar {
                display: flex;
                flex-wrap: wrap;
                font-size: 14px;
                color: #666666;
                line-height: 1;
                text-decoration: none;

                @media only screen and (max-width: 1366px) {
                  font-size: 13px;
                }

                &:hover {
                  color: var(--color-primary-hover);
                }

                i {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }

    .user-box {
      display: block;
      margin-left: 30px;

      .notification {
        left: -20px !important;
      }

      @media only screen and (max-width: 1366px) {
        margin-left: 20px;
      }

      .dropdown-toggle {
        background: none;
        padding: 0 0 0 30px;
        border: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-radius: 0px;

        @media only screen and (max-width: 1366px) {
          padding: 0 0 0 20px;
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 5px;
          bottom: 5px;
          border-left: solid 1px #fff;
        }

        &::after {
          display: none;
        }

        &:not(:disabled):not(.disabled).active:focus,
        &:not(:disabled):not(.disabled):active:focus,
        &:focus {
          box-shadow: none;
          background: none;
          outline: 0;
        }

        p {
          margin: 0;
          font-size: 15px;
          color: #333333;
          line-height: 16px;
          text-align: right;

          @media only screen and (max-width: 1366px) {
            font-size: 12px;
          }

          span {
            width: 100%;
            display: block;
          }
        }

        img {
          margin-left: 12px;
          height: 35px;
        }
      }

      .dropdown-menu {
        width: 345px;
      }

      .notification {
        display: block;
        margin-left: 30px;
        transform: translate(-197px, 35px);
        left: -15px;

        @media only screen and (max-width: 1366px) {
          margin-left: 20px;
        }

        .close-btn {
          position: absolute;
          right: 10px;
          top: 3px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.35);
          border: 0;
          padding: 0;
          background: none;
          padding: 6px 0px;
          margin: 15px 0;
        }

        h2 {
          display: block;
          font-size: 18px;
          color: var(--color-primary-hover);
          line-height: 1;
          // margin     : 15px 0;

          @media only screen and (max-width: 1366px) {
            font-size: 16px;
          }
        }

        .horizontal {
          overflow-x: hidden;
          width: 100%;
          position: relative;
          // height    : 520px !important;
          height: 480px;
          // overflow     : hidden;
        }

        .notification-main {
          display: block;
          transition: all 0.5s;
          left: 0;
          position: relative;

          &.open {
            left: -100%;
            height: 400px;
          }

          .notification-boxs {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            width: 100%;
            margin: 0 0 15px;
            padding: 15px;
            background: rgba(245, 245, 245, 0.53);

            @media only screen and (max-width: 1366px) {
              padding: 10px;
            }

            .content {
              // width  : calc(100% - 18px);
              width: 100% !important;
              padding: 0 0 0 10px;
              background: rgba(245, 245, 245, 0.53);
              padding: 10px;

              h3 {
                display: block;
                font-size: 19px;
                color: #666666;
                line-height: 1;

                @media only screen and (max-width: 1366px) {
                  font-size: 16px;
                }
              }
            }
          }
        }

        .view-code {
          display: block;
          position: absolute;
          left: 100%;
          top: 0;
          transition: all 0.5s;
          width: 100%;
          padding: 0px !important;
          padding-left: 40px;

          &.open {
            left: 0;
          }

          .back-button {
            position: absolute;
            left: 10px;
            top: 4px;
          }

          .heading {
            margin: 0 6px 4px;
            display: flex;
            align-items: center;
          }

          .back-btn {
            margin-right: 10px;
            cursor: pointer;
          }

          .notification-boxs {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            width: 100%;
            // margin      : 0 0 15px;
            padding: 15px;
            background: none !important;
            padding-left: 30px;
            margin: 0 !important;
            padding-bottom: 0 !important;

            @media only screen and (max-width: 1366px) {
              padding: 10px;
            }

            .content {
              width: calc(100% - 15px);
              padding: 10px;
              background: rgba(245, 245, 245, 0.53);

              .btn_pop {
                display: flex;
                justify-content: space-evenly;

                button {
                  margin: 0;
                }
              }

              .full-width {
                min-width: 100% !important;
                width: 100% !important;
              }
            }

            .close-btn {
              position: absolute;
              right: 21px;
              top: 0px;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.35);
              border: 0;
              padding: 0;
              background: none;
              padding: 6px 0px;
              margin: 15px 0;
            }
          }
        }
      }
    }
  }

  .dropdown {
    .dropdown-menu {
      padding: 0;
      border: 0;
      border-radius: 6px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      margin-top: 10px;
      width: 270px;

      .dropdown-item {
        padding: 10px 20px;
        line-height: 1;
        font-size: 15px;
        color: #666666;
      }

      ul {
        padding: 0;
        margin: 0;
        width: 100%;
        list-style: none;

        &.worldwide-boxs {
          margin: 10px;
          padding: 0;
          background: rgba(245, 245, 245, 0.53);
          width: calc(100% - 20px);
        }

        li {
          display: block;
          position: relative;

          a {
            display: flex;
            flex-wrap: wrap;
            font-size: 15px;
            color: #666666;
            line-height: 1;
            padding: 10px 15px;
            text-decoration: none;

            &:hover {
              background: none;
              color: var(--color-primary-hover);
            }

            i {
              font-size: 15px;
              margin-right: 10px;
            }

            span {
              color: var(--color-primary-hover);
            }
          }

          .icon-expand {
            position: absolute;
            top: 10px;
            right: 20px;
            color: rgba(0, 0, 0, 0.35);
            z-index: 1;
          }
        }
      }
    }
  }
}

.email-label span {
  font-size: 11px;
}

.email-label {
  // max-width: 215px;
}

.email-label p {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.email-label img {
  height: 29px !important;
  margin-top: -8px;
}

.search-boxs {
  display: block;
  position: relative;
  width: 100%;
  margin-right: 20px;

  @media only screen and (max-width: 1600px) {
    width: 100%;
  }

  @media only screen and (max-width: 1366px) {
    width: 200px;
    margin-right: 0;
  }

  .form-control {
    // border       : 0;
    // border-radius: 50px;
    background: #ffffff;
    padding: 15px 52px 15px 23px;
    height: auto;
    line-height: 1;
    font-size: 14px;
    color: #757280;

    @media only screen and (max-width: 1366px) {
      padding: 15px 42px 15px 20px;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .btn.btn-light {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    margin: 15px 0;
    padding: 0 24px 0 13px;
    background: transparent;
    opacity: 0.3;
    border-left: solid 1px rgba(112, 112, 112, 0.5);

    @media only screen and (max-width: 1366px) {
      padding: 0 13px;
    }

    img {
      display: block;
    }
  }
}
