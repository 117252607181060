.errorPage {
    // background: #ebe7fc;
    display: flex;
    align-items: center;
    padding: 22px 40px;
  
    .imgWrap {
      height: 105px;
      width: 105px;
      display: flex;
      align-items: center;
      margin-right: 20px;
      border-radius: 50%;
      padding: 20px;
      background: var(--color-primary);
  
      img {
        width: 75px;
      }
    }
  
    .error_text {
      p {
        font-size: 15px;
      }
    }
  }
  